<template>
  <v-dialog :value="true" max-width="485px" @click:outside="emitClose">
    <div class="contact-dialog">
      <img class="contact-dialog__close" src="@/assets/landing/close.svg" @click="emitClose" />
      <ContactUsContent @close="emitClose" />
    </div>
  </v-dialog>
</template>
<script>
import ContactUsContent from "./ContactUsContent.vue";

export default {
  name: "ContactUsDialog",
  components: {
    ContactUsContent,
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-dialog {
  background: #fff;
  position: relative;

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 5;
  }
}
</style>
